/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/algoliasearch@4.9.1/dist/algoliasearch.umd.min.js
 * - /npm/instantsearch.js@4.23.0/dist/instantsearch.production.min.js
 * - /npm/algoliasearch-helper@3.4.4/dist/algoliasearch.helper.min.js
 * - /npm/moment@2.29.1/moment.min.js
 * - /npm/fuse.js@6.4.6/dist/fuse.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
